import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CountryModalComponent } from './country-modal/country-modal.component';
import { SliderComponent } from './slider/slider.component';

@NgModule({
  declarations: [HeaderComponent,FooterComponent,CountryModalComponent,SliderComponent],
  imports: [
    CommonModule
  ],
  exports:[HeaderComponent,FooterComponent,CountryModalComponent,SliderComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class CommonmodulesModule { }
