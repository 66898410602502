import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-country-modal',
  templateUrl: './country-modal.component.html',
  styleUrls: ['./country-modal.component.scss'],
})
export class CountryModalComponent implements OnInit {

  @Input() country_data: any = [];
  backupData: any = [];
  newData: any = [];
  filterTerm: string;
  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {
    this.backupData = this.country_data;
  }
  searchTerms(event) {
    this.restName();
    let val = event.target.value;
    if (val && val.trim() !== '') {
      this.country_data = this.country_data.filter((item: any) => {
        let preferData = item.name;
        return (preferData.toLowerCase().substring(0, val.length) == val.toLowerCase());
      });
    }
  }

  restName() {
    this.country_data = this.backupData;
  }
  async close(country?) {
    await this.modalCtrl.dismiss(country);
  }

}
