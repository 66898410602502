import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
varSelectValue:string='1';
walletName:string='';
objTitle={
  '1':'',
  '2':'My Contests',
  '3':'Wallet',
  '4':'Profile'
}
  constructor(public objNavCtrll : NavController) { }

  ngOnInit() {
    if(localStorage.getItem('tabValue')){
    this.varSelectValue = localStorage.getItem('tabValue');
    console.log(this.varSelectValue)
  }
  if(localStorage.getItem('footer')){
    this.walletName = this.objTitle[localStorage.getItem('footer')]
  }
  }
  

  opendata(value:string, page:string){    
    this.objNavCtrll.navigateForward('/'+page)
    localStorage.setItem('tabValue',value)
  }

  ionViewWillEnter(){
    this.varSelectValue = localStorage.getItem('tabValue');
    this.walletName = this.objTitle[localStorage.getItem('footer')]
  }
}
