import { NgModule,HostListener } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';

import { CommonmodulesModule } from './components/commonmodules.module';
import { ShortnamePipeModule } from './pipes/shortname.module';
import { TruncatePipeModule } from './pipes/truncate.module';
import { DebounceClickModule } from './directives/debounce-click.module';
// import { pageTransition } from '../app/animations/page-transition';
let deviceWidth;

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot({
    mode: 'md',
    // navAnimation: pageTransition 
}), AppRoutingModule,HttpClientModule, FormsModule,
    ReactiveFormsModule,CommonmodulesModule,DebounceClickModule],
  providers: [Camera,Geolocation,ShortnamePipeModule,TruncatePipeModule,{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {
  @HostListener('window:resize', ['$event']) getScreenSize(event?) {
    deviceWidth = window.innerWidth;
    console.log(event);
  }

}
