import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class Config {
    public value = 2;
}

export const logo = '"../../assets/images/landing.svg';

export const preloader = '<img src="../assets/images/loader.gif">';

export const currency = "INR";

export const isBrower='1';   // browser = 1 
                             // android/IOS = 2


//URL
export const url ="https://api.legendary11.in/api/"; 
export const aws_url ="https://legendary11.s3.ap-south-1.amazonaws.com/FinalPlayers11Json/";
export const payment_url="https://api.shotmaar.com/pay/deposite";
export const ifscUrl = "https://ifsc.razorpay.com/";
export const appVersion ="1.1";
