import { Component, OnInit } from '@angular/core';
import { CommonserviceService } from '../../services/commonservice.service';
import {  ModalController } from '@ionic/angular';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {
  varBanner=[];
  constructor(public commonService: CommonserviceService,public modalCtrl : ModalController) { }

  ngOnInit() {
    this.getBanner();
  }
  getBanner(){

    this.commonService.commonApiService('get','getAdBanners').then((result:any)=>{
      if(result.status != 201){
      this.varBanner = result.content;
    }
    });
  }
  close()
  {
    this.modalCtrl.dismiss();
  }

}
