import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndiaCurrencyPipe, ShortnamePipe } from './shortname.pipe';


@NgModule({
  declarations: [ShortnamePipe, IndiaCurrencyPipe],
  imports: [
    CommonModule
  ],
  exports:[ShortnamePipe, IndiaCurrencyPipe]
})
export class ShortnamePipeModule { }
